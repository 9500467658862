<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <h3 class="display-3 bold blue-light margin-0">Summary</h3>
      <a
        v-on:click="this.$emit('go-to-step', 'attendees')"
        class="d-block margin-2"
        >Edit</a
      >
      <table class="product-basket" v-for="attendee in attendees">
        <template v-if="attendee.productIds.length > 0">
          <tr>
            <td class="attendee bold blue-light display-5" colspan="3">
              {{ attendee.firstName }} {{ attendee.lastName }}
            </td>
          </tr>

          <tr
            class="product-row summary"
            v-for="product in getAttendeeProducts(attendee)"
          >
            <td class="product-amount large">1</td>
            <td class="product-title large">
              <span class="large bold blue-light">{{ product.category }}:</span>
              {{ product.name }}<br /><span class="small blue-light">{{
                event.title
              }}</span>
            </td>
            <td class="product-price large align-right">
              {{ product.currency }} {{ product.price }}
            </td>
          </tr>
        </template>
      </table>

      <table class="product-basket">
        <tr class="product-row" v-for="discountTotal in summary.discountTotals">
          <td class="product-title">
            <span class="large bold green">Promotion code: </span>
            {{ discountTotal.promotionCode }}<br />
            <span class="small green"
              >{{ discountTotal.amount }}x {{ discountTotal.name }} -
              {{ discountTotal.discountPercentage }}% discount</span
            >
          </td>
          <td class="product-amount large"></td>
          <td class="product-price large">
            -{{ summary.totals.currency }}{{ discountTotal.subtotalAbsolute }}
          </td>
        </tr>
      </table>

      <div class="d-flex justify-content-between">
        <p class="display-6 bold blue-light ms-3">Total</p>
        <p class="display-6 bold blue-light me-3">
          {{ summary.totals.currency }} {{ summary.totals.total }}<br />
        </p>
      </div>
      <div class="d-flex justify-content-between">
        <p class="display-6 blue-light ms-3">VAT</p>
        <p class="display-6 blue-light me-3">
          {{ summary.totals.currency }} {{ summary.totals.vat }}<br />
        </p>
      </div>
      <div class="d-flex justify-content-between mb-3">
        <p class="display-4 bold blue-light ms-3">Total including VAT</p>
        <p class="display-4 bold blue-light me-3">
          {{ summary.totals.currency }} {{ summary.totals.totalInclVat }}<br />
        </p>
      </div>
    </div>
    <div class="col-md-1"></div>
    <div class="col-12 col-md-5">
      <h3
        v-if="summary.totals.total > 0"
        class="display-3 bold blue-light margin-2"
      >
        Payment
      </h3>
      <h3 v-else class="display-3 bold blue-light margin-2">
        Confirm Your Registration
      </h3>

      <fieldset v-if="summary.totals.total > 0" class="payment-options">
        <div class="selectbox">
          <input
            id="stripe"
            type="radio"
            v-model="payment.payByInvoice"
            :value="false"
          />
          <label for="stripe">
            <p class="display-5 bold blue-light margin-0">
              Pay by
              <span class="display-5 bold blue-light margin-0">{{
                paymentMethodsText
              }}</span>
            </p>
            <p class="margin-0">Preferred payment option</p>
          </label>
        </div>
        <div class="selectbox">
          <input
            id="invoice"
            type="radio"
            v-model="payment.payByInvoice"
            :value="true"
          />
          <label for="invoice">
            <p class="display-5 bold blue-light margin-0">Pay by Invoice</p>
            <p class="margin-0">
              {{ payment.invoiceChargePercentage }} administrative fee wll be
              charged
            </p>
          </label>
        </div>
      </fieldset>

      <p v-else class="large">
        No payment is required.<br />
        Please finalize your order to confirm your participation.
      </p>

      <button
        class="green medium mt-4"
        v-on:click="finalizeOrder"
        :disabled="submitDisabled"
      >
        <template v-if="summary.totals.total > 0">Submit payment</template>
        <template v-else>Finalize</template>
        <i class="fa-solid fa-chevron-right"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Payment',
  components: {},
  data() {
    return {
      submitDisabled: false,
    }
  },
  props: {
    event: Object,
    attendees: Array,
    urls: Object,
    summary: Object,
    payment: Object,
    products: Array,
    getError: Function,
  },

  emits: ['done-payment', 'go-to-step'],
  computed: {
    paymentMethodsText() {
      const names = this.event.paymentMethods.map((method) => method.name)
      let text = names.join(', ')
      if (names.length > 1) {
        const lastComma = text.lastIndexOf(',')
        text = text.slice(0, lastComma) + ' or' + text.slice(lastComma + 1)
      }
      return text
    },
  },
  methods: {
    getAttendeeProducts(attendee) {
      return attendee.productIds.map((productId) => {
        return this.products.find((product) => product.id === productId)
      })
    },
    finalizeOrder() {
      this.submitDisabled = true
      this.$emit('done-payment')
      //   after 5 seconds, re-enable the submit button to allow the user to try again
      setTimeout(() => {
        this.submitDisabled = false
      }, 5000)
    },
  },
}
</script>

<style scoped></style>
