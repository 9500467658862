import Trackers from '../trackers/Trackers'

export default class {
  constructor() {
    this.init()
  }

  init() {
    const cookieAlert = $('#cookie-jar')
    if (localStorage.getItem('cookies-consent') === null) {
      cookieAlert.show()
    } else if (localStorage.getItem('analytical') === 'yes') {
      new Trackers().loadGoogleTagManager()
    }

    $('#allow-selection').on('click', () => {
      let analyticalcheck = $('#analytical').is(':checked')
      let marketingcheck = $('#marketing').is(':checked')

      if (analyticalcheck === true) {
        localStorage.setItem('analytical', 'yes')
        new Trackers().loadGoogleTagManager()
      } else {
        localStorage.setItem('analytical', 'no')
      }

      if (marketingcheck === true) {
        localStorage.setItem('marketing', 'yes')
      } else {
        localStorage.setItem('marketing', 'no')
      }

      localStorage.setItem('cookies-consent', 'yes')
      cookieAlert.hide()
    })

    $('#customize-cookies').on('click', () => {
      $('#cookie-options').show()
      $('#allow-selection').show()
      $('#cookie-info').hide()
      $('#customize-cookies').hide()
    })

    $('#accept-cookies').on('click', () => {
      localStorage.setItem('cookies-consent', 'yes')
      localStorage.setItem('analytical', 'yes')
      localStorage.setItem('marketing', 'yes')
      new Trackers().loadGoogleTagManager()
      cookieAlert.hide()
    })

    $('.reject-cookies').on('click', () => {
      localStorage.setItem('cookies-consent', 'yes')
      localStorage.setItem('analytical', 'no')
      localStorage.setItem('marketing', 'no')
      cookieAlert.hide()
    })

    $('.edit-cookies').on('click', () => {
      cookieAlert.show()
    })
  }
}
