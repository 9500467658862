<template>
  <section>
    <div v-if="domainOrganizations.length > 0">
      <p v-if="domainOrganizations.length === 1">
        Based on the domain of your email address you are connected with:
      </p>
      <p v-if="domainOrganizations.length > 1">
        There are multiple organizations in our directory matching your email
        domain: <span class="bold">{{ foundDomain }}</span>
      </p>
    </div>
    <div v-if="domainOrganizations.length > 0">
      <fieldset class="organization-select margin-0">
        <div class="selectbox" v-for="organization in domainOrganizations">
          <input
            :id="`domain_organization_${organization.id}`"
            type="radio"
            name="domain_organization"
            :checked="
              selectedOrganization &&
              selectedOrganization.id === organization.id
            "
            @click="selectDomainOrganization(organization)"
          />
          <label :for="`domain_organization_${organization.id}`">
            <p class="margin-0">
              <span class="bold margin-0">{{ organization.name }}</span
              ><br />
              <span class="small">{{ organization.domain }}</span>
            </p>
          </label>
        </div>
      </fieldset>
    </div>
    <div class="mb-4" v-else>
      We couldn't find any organizations matching your email domain
      <strong>{{ foundDomain }}</strong
      >. Please choose one or add a new one.
    </div>
    <p>
      <span v-if="domainOrganizations.length === 1"
        >If this not the organization you are working for you
      </span>
      <span v-if="domainOrganizations.length > 1"
        >If your organization is not listed you
      </span>
      <a
        v-if="domainOrganizations.length > 0"
        href="#"
        @click="enableOrganizationInput"
        >can choose another or add a new one.</a
      >
    </p>
    <div v-if="selectedOrganization && !selectedIsDomainOrganization()">
      <fieldset class="organization-select margin-0">
        <div class="selectbox">
          <input
            :id="`selected_organization_${selectedOrganization.id}`"
            type="radio"
            name="selected_organization"
            :checked="
              selectedOrganization &&
              selectedOrganization.id === selectedOrganization.id
            "
          />
          <label :for="`selected_organization${selectedOrganization.id}`">
            <p class="margin-0">
              <span class="bold margin-0">{{ selectedOrganization.name }}</span
              ><br />
              <span v-if="selectedOrganization.domain" class="small">{{
                selectedOrganization.domain
              }}</span>
              <span v-else class="small">{{ foundDomain }}</span>
            </p>
          </label>
        </div>
      </fieldset>
    </div>
    <div v-else-if="!selectedOrganization">
      <label for="id_organization_name">Organization name:</label>
      <input
        id="id_organization_name"
        class="mb-0"
        type="text"
        name="organization_name"
        v-model="form.organization_name"
        @input="autoCompleteOrganizations"
        ref="organizationInput"
      />

      <ul class="search-results">
        <li
          class="organization-select mt-1"
          v-if="showUseOrganizationName()"
          @click="selectNewOrganization(form.organization_name)"
        >
          <div class="selectbox">
            <input type="radio" name="organization" />
            <label>
              <p class="margin-0 pt-1 mb-0">
                <span class="small margin-0">My organization is not listed</span
                ><br />
                Use <span class="bold">"{{ form.organization_name }}"</span>
              </p>
            </label>
          </div>
        </li>

        <li
          class="organization-select"
          v-for="organization in foundOrganizations"
          @click="selectFoundOrganization(organization)"
        >
          <div class="selectbox">
            <input
              :id="`organization_${organization.id}`"
              type="radio"
              name="organization"
              :checked="
                selectedOrganization &&
                selectedOrganization.id === organization.id
              "
              @click="selectFoundOrganization(organization)"
            />
            <label :for="`organization_${organization.id}`">
              <p class="margin-0">
                <span
                  v-html="
                    organization.name.replace(
                      //   make the search term bold
                      new RegExp(
                        form.organization_name.replace(
                          // match any character that has a special meaning in a regular expression
                          /[-\/\\^$*+?.()|[\]{}]/g,
                          // insert a backslash before any such character
                          '\\$&'
                        ),
                        'i'
                      ),
                      '<span class=\'bold margin-0\'>$&</span>'
                    )
                  "
                ></span>
                <br /><span class="small">{{ organization.domain }} </span>
              </p>
            </label>
          </div>
        </li>
      </ul>
    </div>
    <ul>
      <li v-for="error in errors.organization">
        <span v-if="error.code === 'invalid_domain'">
          <div class="notification yellow mb-2 margin-n" id="accountinfo">
            <i class="fa-sharp fa-regular fa-circle-info"></i>
            <p class="inline" v-html="error.message"></p>
            <p class="margin-0">Please choose one of the following options:</p>
            <ul>
              <li>
                <a href="#" @click.prevent="goToPersonalStep"
                  >Update your e-mail address</a
                >
                to match the domain.
              </li>
              <li>
                Proceed with your current email and organization. Note that your
                connection with {{ selectedOrganization.name }} will be pending
                upon review.
              </li>
            </ul>
          </div>
        </span>
        <span v-else v-html="error.message"></span>
      </li>
    </ul>
  </section>
</template>
<script>
import FormErrors from '../../components/FormFieldErrors.vue'
import useBackend from '../../components/useBackend'

export default {
  name: 'OrganizationSelect',
  components: { FormErrors },
  props: {
    form: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    urls: {
      type: Object,
      required: true,
    },
    csrfToken: {
      type: String,
      required: true,
    },
    selectOrganization: {
      type: Function,
      required: true,
    },
    clearSelectedOrganization: {
      type: Function,
      required: true,
    },
    selectedOrganization: {
      type: [Object, null],
      required: true,
    },
    goToPersonalStep: {
      type: Function,
      required: true,
    },
    validate: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const { callBackend, systemError } = useBackend()

    return {
      callBackend,
      systemError,
    }
  },
  data() {
    return {
      foundDomain: '',
      foundOrganizations: [],
      domainOrganizations: [],
    }
  },
  mounted() {
    this.autoCompleteOrganizations()
    this.loadDomainOrganizations()
    document.addEventListener('click', this.hideSearchResults)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.hideSearchResults)
  },
  methods: {
    loadDomainOrganizations() {
      this.callBackend({
        url: this.urls.domainLookup,
        csrfToken: this.csrfToken,
        payload: {
          email: this.form.email,
        },
      })
        .then((data) => {
          this.foundDomain = data.domain
          this.domainClient = data.client
          this.domainOrganizations = data.organizations
          if (this.domainOrganizations.length > 0) {
            this.selectOrganization(this.domainOrganizations[0], 'domain')
          } else {
            this.selectedOrganization = null
          }
        })
        .catch((err) => {})
    },
    autoCompleteOrganizations() {
      this.clearSelectedOrganization()
      if (this.form.organization_name.length > 1) {
        this.callBackend({
          url: this.urls.organizationAutocomplete,
          csrfToken: this.csrfToken,
          payload: {
            name: this.form.organization_name,
          },
        })
          .then((data) => {
            this.foundOrganizations = data.organizations
          })
          .catch((data) => {
            console.error('error', data)
            this.systemError = true
          })
      }
    },
    hideSearchResults(e) {
      if (
        this.$refs.organizationInput === null ||
        this.$refs.organizationInput.contains(e.target)
      ) {
        return
      }

      this.form.organization_name = ''
      this.foundOrganizations = []
      this.$nextTick(() => {
        this.$refs.organizationInput.focus()
      })
    },
    showUseOrganizationName() {
      if (this.selectedOrganization) {
        return false
      }
      if (this.form.organization_name.length === 0) {
        return false
      }
      return !this.foundOrganizations.some(
        (organization) =>
          organization.name.toLowerCase() ===
          this.form.organization_name.toLowerCase()
      )
    },
    selectDomainOrganization(organization) {
      this.selectOrganization(organization, 'domain')
    },
    selectNewOrganization() {
      this.selectOrganization(
        { name: this.form.organization_name, id: null },
        'new'
      )
      this.foundOrganizations = []
    },
    selectFoundOrganization(organization) {
      this.selectOrganization(organization, 'search')
      this.foundOrganizations = []
    },
    enableOrganizationInput() {
      this.clearSelectedOrganization()
      // wait until the input is visible:
      this.$nextTick(() => {
        // this.$refs.organizationInput.show()
        this.$refs.organizationInput.focus()
      })
    },
    selectedIsDomainOrganization() {
      if (this.selectedOrganization && this.domainOrganizations.length > 0) {
        return this.domainOrganizations.some((org) => {
          return org.id === this.selectedOrganization.id
        })
      }
      return false
    },
  },
}
</script>
