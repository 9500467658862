import AttendeeAddOns from './AttendeeAddOns.vue'
import Billing from '../shared/Billing.vue'
import Payment from '../shared/Payment.vue'
import ResetRegistration from '../shared/ResetRegistration.vue'
import StepsMenu from '../shared/StepsMenu.vue'
import billingData from '../shared/billingData'
import paymentData from '../shared/paymentData'
import summaryData from '../shared/summaryData'
import useValidation from '../shared/useValidation'
import useBackend from '../../components/useBackend'
import SystemError from '../../components/SystemError.vue'

export default {
  setup() {
    const { errors, handleValidationResponse, getError, getStepErrors } =
      useValidation()
    const { callBackend, systemError } = useBackend()

    return {
      callBackend,
      errors,
      getError,
      getStepErrors,
      handleValidationResponse,
      systemError,
    }
  },
  name: 'AddAddOns',
  components: {
    AttendeeAddOns,
    Billing,
    Payment,
    ResetRegistration,
    StepsMenu,
    SystemError,
  },
  data() {
    return {
      event: {
        title: '',
        location: '',
        attendeeTargetCount: 0,
        startDate: '',
        endDate: '',
        ticketInfo: '',
        preferredRatesInfo: '',
      },
      urls: {
        basket: '',
        login: '',
        register: '',
        reregister: '',
        solarplazaLogo: '',
        privacyPolicy: '',
      },
      tickets: {
        promotionCode: '',
        selection: [
          {
            id: 0,
            amount: 0,
          },
        ],
      },
      products: [
        {
          id: 0,
          category: '',
          name: '',
          type: '',
          currency: '€',
          prices: {
            original: 0,
            discount: 0,
            discounted: 0,
            vat: 0,
            gross: 0,
          },
          amount: 0,
          maxPerOrder: 0,
        },
      ],
      account: {
        isAuthenticated: false,
        kind: '',
      },
      billing: { ...billingData },
      payment: { ...paymentData },
      countries: [{ id: '', name: '' }],
      states: [{ code: '', name: '' }],
      hiddenFields: [],
      attendees: [
        {
          id: 0,
          firstName: '',
          lastName: '',
          organizationName: '',
          jobTitle: '',
          email: '',
          phone: '',
          dietary: '',
          dietaryOther: '',
          originalProductIds: [],
          productIds: [],
        },
      ],
      dietaryOptions: [],
      summary: {
        ...summaryData,
      },
      steps: [
        {
          name: 'attendees',
          label: 'Attendee details',
        },
        {
          name: 'billing',
          label: 'Billing information',
        },
        {
          name: 'payment',
          label: 'Summary & payment',
        },
      ],
      systemError: false,
      currentStep: 'attendees',
      validateTimeout: null,
      errors: [{ step: '', index: 0, field: '', messages: [], show: false }],
    }
  },
  mounted() {
    let initialData = JSON.parse(
      document.getElementById('initialData').textContent
    )

    this.setIfDefined(initialData)
  },
  computed: {
    showStepsMenu() {
      return window.location.hash === '#debug'
    },
  },
  methods: {
    setIfDefined(data) {
      let fields = [
        'account',
        'attendees',
        'billing',
        'countries',
        'csrfToken',
        'currentStep',
        'dietaryOptions',
        'event',
        'hiddenFields',
        'payment',
        'products',
        'states',
        'summary',
        'urls',
      ]
      fields.forEach((field) => {
        if (data[field]) {
          this[field] = data[field]
        }
      })
    },
    getBillingPayload() {
      let payload = {}
      // copy billing without hidden fields
      Object.keys(this.billing).forEach((key) => {
        if (!this.hiddenFields.includes(key)) {
          payload[key] = this.billing[key]
        } else {
          console.log('Not including hidden field', key)
        }
      })
      return payload
    },
    updateBasket({ validationTarget = null, nextStep = null }) {
      let payload = {
        currentStep: this.currentStep,
        nextStep: nextStep,
        validationTarget: validationTarget,
      }
      if (this.currentStep === 'attendees') {
        payload.attendees = this.attendees
      } else if (this.currentStep === 'billing') {
        payload.billing = this.getBillingPayload()
      }
      this.callBackend({
        url: this.urls.basket,
        csrfToken: this.csrfToken,
        payload: payload,
      })
        .then((data) => {
          console.log('handle updateBasket response', data)
          this.setIfDefined(data)
          this.handleValidationResponse(data.validation, this.currentStep)
        })
        .catch((err) => {
          console.error('updateBasket error', err)
        })
    },
    validate(index, fields) {
      this.updateBasket({ validationTarget: { index: index, fields: fields } })
    },
    delayedValidate(index, fields) {
      // validate after some delay, cancel previous timeout
      clearTimeout(this.validateTimeout)
      this.validateTimeout = setTimeout(() => {
        this.validate(index, fields)
      }, 1000)
    },
    goToStep(step) {
      this.currentStep = step
    },
    doneAttendees() {
      this.updateBasket({ nextStep: 'billing' })
    },
    doneBilling() {
      this.updateBasket({ nextStep: 'payment' })
    },
    donePayment() {
      console.log('donePayment')
      let payload = {
        billing: this.getBillingPayload(),
        attendees: this.attendees,
        payment: this.payment,
      }
      this.callBackend({
        url: this.urls.done,
        csrfToken: this.csrfToken,
        payload: payload,
      })
        .then((data) => {
          console.log('donePayment response', data)
          if (data.success) {
            window.location.href = data.checkoutUrl
          } else {
            this.handleValidationResponse(data.validation)
            // set this.currentStep to the first step with errors:
            this.currentStep = this.errors[0].step
          }
        })
        .catch((err) => {})
    },
  },
}
