<template>
  <div class="row">
    <div class="col-md-6 personal-information">
      <h3 class="display-3 margin-2 bold blue-light">Selecting Your Add-ons</h3>

      <p class="large margin-2">
        As a confirmed event attendee, with your main ticket already taken care
        of, you have the option to purchase additional add-ons to upgrade your
        event experience.
      </p>

      <div class="row">
        <div class="attendee-details">
          <ul class="nav nav-tabs">
            <li
              class="nav-item"
              role="presentation"
              v-for="(attendee, index) in attendees"
              :key="index"
            >
              <button
                class="nav-link"
                :id="'attendee-nav' + (index + 1)"
                type="button"
                role="tab"
                :class="index === activeTab ? 'active' : ''"
                @click="activeTab = index"
              >
                Ticket {{ index + 1 }}
              </button>
            </li>
          </ul>

          <div class="tab-content form-box" id="ticket-information">
            <div
              class="tab-pane fade"
              role="tabpanel"
              v-for="(attendee, index) in attendees"
              :key="index"
              :id="'ticket' + (index + 1)"
              :class="index === activeTab ? ' show active' : ''"
            >
              <div class="col-12">
                <p class="display-5 bold blue-light margin-0">
                  {{ attendee.firstName }} {{ attendee.lastName }}
                </p>
                <p class="display-5 blue-light">
                  {{ attendee.organizationName }}
                </p>

                <label for="dietary">Dietary requirements:</label>
                <select id="dietary" v-model="attendee.dietary">
                  <option
                    v-for="option in dietaryOptions"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>

                <FormErrors
                  :errors="getError('attendees', index, 'dietary')"
                ></FormErrors>
                <template v-if="attendee.dietary === 'other'">
                  <label for="dietaryOther">Please specify:</label>
                  <input
                    type="text"
                    id="dietaryOther"
                    v-model="attendee.dietaryOther"
                    maxlength="254"
                /></template>

                <FormErrors
                  :errors="getError('attendees', index, 'dietaryOther')"
                ></FormErrors>
                <FormErrors
                  :errors="getError('attendees', index, '__all__')"
                ></FormErrors>

                <div v-if="getAvailableAddOns().length > 0">
                  <hr />
                  <p class="bold">
                    Choose add-ons to enhance your event experience:
                  </p>
                  <div
                    v-for="(addOn, addOnIndex) in getAvailableAddOns()"
                    :key="index"
                  >
                    <label :for="`add-on-${index}-${addOnIndex}`">
                      <input
                        class="margin-0"
                        :id="`add-on-${index}-${addOnIndex}`"
                        type="checkbox"
                        :checked="getAddOnSelected(addOn, attendee)"
                        :disabled="getAddOnDisabled(addOn, attendee)"
                        @change="
                          setAddOnSelected(
                            addOn,
                            attendee,
                            addOnIndex,
                            $event.target.checked
                          )
                        "
                      /><span class="margin-0">
                        {{ addOn.name }}
                        {{ addOn.currency }}
                        {{ addOn.price }}
                      </span>
                    </label>
                  </div>
                </div>
                <button
                  v-if="index < attendees.length - 1"
                  class="medium blue-light mt-4"
                  v-on:click="nextAttendee()"
                >
                  Next attendee <i class="fas fa-chevron-right"></i>
                </button>
                <button
                  v-else
                  class="medium green mt-4"
                  v-on:click="$emit('done-attendees')"
                  :disabled="continueButtonDisabled"
                >
                  Continue <i class="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-1"></div>

    <div class="d-none d-md-block col-md-5 order-summary">
      <h3 class="display-3 bold margin-2 blue-light">Your order</h3>

      <OrderSummary
        v-if="summary.productTotals.length > 0"
        :event="event"
        :summary="summary"
      ></OrderSummary>
      <p v-else class="large italic">No products are selected.</p>
    </div>
  </div>
</template>

<script>
import FormErrors from '../../components/FormFieldErrors.vue'
import OrderSummary from '../shared/OrderSummary.vue'

export default {
  name: 'AttendeeAddOns',
  components: { FormErrors, OrderSummary },
  props: {
    event: Object,
    attendees: Array,
    dietaryOptions: Array,
    summary: Object,
    products: Array,
    getError: Function,
    getStepErrors: Function,
  },
  emits: ['done-attendees', 'validate', 'delayed-validate'],
  data() {
    return {
      activeTab: 0,
    }
  },
  methods: {
    getAvailableAddOns() {
      return this.products.filter((product) => {
        return product.type === 'add_on'
      })
    },
    getAddOnSelected(product, attendee) {
      return (
        attendee.originalProductIds.includes(product.id) ||
        attendee.productIds.includes(product.id)
      )
    },
    getAddOnDisabled(product, attendee) {
      return attendee.originalProductIds.includes(product.id)
    },
    setAddOnSelected(product, attendee, index, checked) {
      attendee.productIds = attendee.productIds.filter((attendeeProductId) => {
        return attendeeProductId !== product.id
      })

      if (checked) {
        attendee.productIds.push(product.id)
      }
      this.$emit('validate', index, ['products'])
    },
    nextAttendee() {
      this.activeTab++
      if (this.activeTab >= this.attendees.length) {
        this.activeTab = 0
      }
    },
  },
  computed: {
    continueButtonDisabled() {
      // return true if no new add-ons have been selected
      return this.attendees.every((attendee) => {
        return attendee.productIds.length === 0
      })
    },
  },
}
</script>

<style scoped></style>
