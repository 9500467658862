<template>
  <div class="container" v-if="organization && options">
    <div class="row">
      <div class="col-12 col-md-7">
        <section class="s-flex-horizontal margin-1">
          <h2 class="display-1 bold blue margin-0">{{ organization.name }}</h2>
          <i
            title="Edit this organization's name and description"
            class="fas fa-pencil icon-button"
            @click="display('edit-name')"
          ></i>
        </section>

        <p v-if="organization.description" class="display-6 margin-2">
          {{ organization.description }}
        </p>
        <p v-else>
          {{ organization.name }} has no organization description yet.
        </p>

        <div class="row">
          <div class="col-12">
            <section class="s-flex-horizontal margin-0">
              <h3 class="display-4 margin-0 bold blue">Priority tags</h3>
              <i
                title="The key tags that define what this organization is all about"
                class="fas fa-pencil icon-button"
                @click="display('edit-tags')"
              ></i>
            </section>
            <p class="fst-italic margin-0">
              Select up to 5 tags that define your organization “in a nutshell”.
            </p>
            <div class="organization-tags">
              <span
                v-for="tag in organization.priority_tags"
                class="tag orange"
                >{{ tag.label }}</span
              >
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <section class="s-flex-horizontal margin-0">
              <h3 class="display-4 margin-0 bold blue">Organization types</h3>
              <i
                title="The type of this organization"
                class="fas fa-pencil icon-button"
                @click="display('edit-organization-types')"
              ></i>
            </section>
            <p class="fst-italic margin-0">
              Define your organization through our pre-set list of organization
              types and sub-types.
            </p>
            <div class="organization-tags">
              <span
                v-for="type in organization.organization_types"
                class="tag orange"
                >{{ type.label }}</span
              >
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <section class="s-flex-horizontal margin-0">
              <h3 class="display-4 margin-0 bold blue">Products / Services</h3>
              <i
                title="The main products and services this organization offers"
                class="fas fa-pencil icon-button"
                @click="display('edit-products')"
              ></i>
            </section>
            <p class="fst-italic margin-0">
              Define the main products and/or services you offer.
            </p>
            <div class="organization-tags">
              <span
                v-for="product in organization.products"
                class="tag orange"
                >{{ product.label }}</span
              >
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <section class="s-flex-horizontal margin-0">
              <h3 class="display-4 margin-0 bold blue">Applications</h3>
              <i
                title="The main installation scale(s) and type(s) this organization is involved with"
                class="fas fa-pencil icon-button"
                @click="display('edit-applications')"
              ></i>
            </section>
            <p class="fst-italic margin-0">
              Define the main installation scale(s) and type(s) of the RE
              applications your activities relate to.
            </p>
            <div class="organization-tags">
              <span
                v-for="application in organization.applications"
                class="tag orange"
                >{{ application.label }}</span
              >
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <section class="s-flex-horizontal margin-0">
              <h3 class="display-4 margin-0 bold blue">Technologies</h3>
              <i
                title="The technologies this organization is focused on"
                class="fas fa-pencil icon-button"
                @click="display('edit-technologies')"
              ></i>
            </section>
            <p class="fst-italic margin-0">
              Select the technologies your organization is focused on.
            </p>
            <div class="organization-tags">
              <span
                v-for="technology in organization.technologies"
                class="tag orange"
                >{{ technology.label }}</span
              >
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 organization-geographies">
            <section class="s-flex-horizontal">
              <h3 class="display-4 margin-0 bold blue">
                Geographies active in
              </h3>
              <i
                title="The geographies this organization is active in and is offering their services and/or products to"
                class="fas fa-pencil icon-button"
                @click="display('edit-geographies')"
              ></i>
            </section>

            <p class="fst-italic margin-0">
              Pinpoint in which geographies your organization is active in.
            </p>
            <section class="bullet-items">
              <span v-for="geography in organization.geographies">
                {{ geography.label }}
              </span>
            </section>

            <!--              <div class="organization-tags">-->
            <!--                {% if organization.geographies.continents|length > 1 %}-->
            <!--                {% for continent in organization.geographies.continents %}-->
            <!--                <p v-for="continent in organization.geographies.continents" -->
            <!--                   class="large inline">{% if not forloop.first %}<i class="fa-solid fa-circle geo-bullet"></i>{% endif %}{{ continent.name }}</p>-->
            <!--                {% endfor %}-->
            <!--                {% else %}-->
            <!--                {% for country in organization.geographies.countries %}-->
            <!--                <p class="large inline">{% if not forloop.first %}<i class="fa-solid fa-circle geo-bullet"></i>{% endif %}{{ country.name }}</p>-->
            <!--                {% endfor %}-->
            <!--                {% endif %}-->
            <!--              </div>-->
          </div>
        </div>

        <div class="row" v-if="false">
          <!-- disabled for now as it is too buggy  -->
          <div class="col-12 organization-offices">
            <section class="s-flex-horizontal">
              <h3 class="display-4 margin-0 bold blue">Local offices</h3>
              <i
                title="The local offices of this multi-national organization"
                class="fas fa-pencil icon-button"
                @click="display('edit-offices')"
              ></i>
            </section>
            <p class="fst-italic margin-0">
              Please list your local offices here.
            </p>
            <section class="bullet-items">
              <span v-for="office in organization.office_locations">
                <a :href="office.website_url">{{ office.label }}</a>
              </span>
            </section>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-5">
        <div class="organization-tile">
          <div class="organization-logo">
            <i
              title="Edit this organization's logo"
              class="fas fa-pencil icon-button icon-button-top-right"
              @click="display('edit-logo')"
            ></i>
            <img
              v-if="organization.logo"
              class="logo"
              alt="logo"
              :src="organization.logo"
            />
            <img
              v-else
              class="logo"
              alt="logo missing image"
              src="/static/images/default-organization.png"
            />
          </div>

          <template v-if="organization.active_since_year">
            <i class="fa-light fa-egg"></i>
            <p class="large">Founded in {{ organization.active_since_year }}</p>
          </template>

          <template v-if="organization.important_company_changes">
            <i class="fa-light fa-clock"></i>
            <p class="large">
              Status: {{ organization.important_company_changes }} since:
              {{ organization.change_date }}
            </p>
          </template>

          <template v-if="organization.located_in_country.label">
            <i class="fa-light fa-building"></i>
            <p class="large">
              Headquartered in {{ organization.located_in_country.label }}
            </p>
          </template>

          <template v-if="organization.employee_count">
            <i class="fa-light fa-users"></i>
            <p class="large">{{ $t(organization.employee_count) }}</p>
          </template>

          <template v-if="organization.client">
            <p class="large">
              {{ organization.client.member_set.count }} members
            </p>
          </template>

          <template v-if="organization.website_url">
            <i class="fa-light fa-globe"></i>
            <a href="{{ organization.website_url }}" target="_blank"
              ><p class="large orange">Website</p></a
            >
          </template>

          <template v-if="organization.linkedin_url">
            <i class="fa-brands fa-linkedin"></i>
            <a href="{{ organization.linkedin_url }}" target="_blank"
              ><p class="large orange">LinkedIn</p></a
            >
          </template>

          <div class="s-flex-horizontal-right">
            <i
              title="Edit this organization's details"
              class="fas fa-pencil icon-button"
              @click="display('edit-details')"
            ></i>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <section class="s-flex-horizontal">
          <h3 class="display-4 margin-0 bold blue">Value chain position</h3>
          <div class="s-flex-horizontal-right">
            <i
              title="The part(s) of the value chain this organization is active in or is offering services to"
              class="fas fa-pencil icon-button"
              @click="display('edit-value-chain')"
            ></i>
          </div>
        </section>
        <p class="fst-italic">
          Define which part(s) of the value chain your organization is active in
          or is offering services to.
        </p>
        <div class="activity-container">
          <div
            v-for="(activity, index) in options.activities"
            class="activity"
            :class="{
              'organization-activity': organization.activities
                .map((item) => item.value)
                .includes(activity.value),
            }"
            :style="`z-index: -${index}`"
          >
            <p class="display-6 margin-0">{{ activity.label }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <teleport to="body">
    <transition name="fade">
      <section
        class="s-modal-backdrop"
        @click="$emit('close')"
        @scroll.prevent
        @wheel.prevent
        @touchmove.prevent
        v-if="visible"
      >
        <section class="s-popup-container">
          <article
            class="s-modal-popup"
            @keyup.esc.prevent="$emit('close')"
            @click.stop
            :style="style"
          >
            <i
              class="fas fa-times blue close-button"
              @click="close()"
              style="position: absolute; right: 20px; top: 20px"
            ></i>
            <component
              :is="component"
              :organization="organization"
              :urls="urls"
              :options="options"
              @close="close()"
              @saved="saved"
            ></component>
          </article>
        </section>
      </section>
    </transition>
  </teleport>
</template>

<script>
import PriorityTagDialog from './PriorityTagDialog.vue'
import OrganizationNameDialog from './OrganizationNameDialog.vue'
import OrganizationDetailsDialog from './OrganizationDetailsDialog.vue'
import LogoDialog from './LogoDialog.vue'
import OrganizationTypeDialog from './OrganizationTypeDialog.vue'
import TechnologyDialog from './TechnologyDialog.vue'
import ApplicationDialog from './ApplicationDialog.vue'
import ProductDialog from './ProductDialog.vue'
import GeographyDialog from './GeographyDialog.vue'
import ValueChainDialog from './ValueChainDialog.vue'
import LocalOfficeDialog from './LocalOfficeDialog.vue'
import { get, getOrganizationOptions, patch, post } from '../api'

const components = new Map()
components.set('edit-name', OrganizationNameDialog)
components.set('edit-tags', PriorityTagDialog)
components.set('edit-details', OrganizationDetailsDialog)
components.set('edit-logo', LogoDialog)
components.set('edit-organization-types', OrganizationTypeDialog)
components.set('edit-technologies', TechnologyDialog)
components.set('edit-applications', ApplicationDialog)
components.set('edit-products', ProductDialog)
components.set('edit-geographies', GeographyDialog)
components.set('edit-value-chain', ValueChainDialog)
components.set('edit-offices', LocalOfficeDialog)

export default {
  name: 'OrganizationEditor',
  data() {
    return {
      style: `max-height: ${window.innerHeight - 40}px`,
      dialogName: undefined,
      visible: false,
      organization: undefined,
      options: undefined,
      urls: Object,
    }
  },
  computed: {
    // Prevent "Vue received a Component which was made a reactive object" error from happening -
    // computed fields are not reactive
    component() {
      return components.get(this.dialogName)
    },
  },
  async mounted() {
    let initialData = JSON.parse(
      document.getElementById('initialData').textContent
    )
    this.urls = initialData.urls
    this.organization = await this.getOrganization()
    this.options = await getOrganizationOptions()
  },
  methods: {
    display(dialogName) {
      this.dialogName = dialogName
      this.visible = true
    },
    close() {
      this.visible = false
      this.dialogName = undefined
    },
    getOrganization() {
      return get(this.urls.getOrganization)
    },
    updateOrganization(payload) {
      return patch(this.urls.updateOrganization, payload)
    },

    async saved(payload) {
      this.close()
      for (const property in payload) {
        this.organization[property] = payload[property]
      }

      this.$forceUpdate()

      if (payload.logo) {
        this.organization.logo = payload.logo.url
      } else {
        await this.updateOrganization(payload)
      }
    },
  },
}
</script>
<style scoped lang="scss">
.bullet-items {
  span:not(:first-child):before {
    content: '•';
    display: inline-flex;
    justify-content: center;
    width: 16px;
  }
}
</style>
